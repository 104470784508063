import React, { useCallback, useEffect, useState, useRef } from "react"
import { Box, Stack, styled, Alert } from "@mui/material"
import { ActivityTable, DemoConteroller, HeaderComp, InfoBar, MainMap, Signals } from "@components"
import { useLocalizationContext, useModeContext } from "@context"

export const Main: React.FC = () => {
  //   const { l } = useLocalizationContext()

  const { isDemo } = useModeContext()

  //   const timeoutId = useRef<NodeJS.Timeout | null>(null)

  //   const [windowWidth, setWindowWidth] = useState(window.innerWidth)

  //   const updateDimensions = useCallback(() => {
  //     if (timeoutId.current) {
  //       clearTimeout(timeoutId.current)
  //     }

  //     timeoutId.current = setTimeout(() => {
  //       setWindowWidth(window.innerWidth)
  //       timeoutId.current = null
  //     }, 200)
  //   }, [])

  //   useEffect(() => {
  //     window.addEventListener("resize", updateDimensions)
  //     return () => window.removeEventListener("resize", updateDimensions)
  //   }, [])

  //   if (windowWidth < 1280) {
  //     return (
  //       <SCenterContainer>
  //         <Alert variant="filled" severity="warning" children={l.ui.lowResMessage} />
  //       </SCenterContainer>
  //     )
  //   }

  return (
    <>
      <HeaderComp />

      <InfoBar />

      <SMainContainer height={"auto"}>
        <Stack width="65%" gap="12px">
          <Box height="100%">
            <MainMap />
          </Box>

          {isDemo && (
            <Box height="36px">
              <DemoConteroller />
            </Box>
          )}

          <Box minHeight="272px">
            <ActivityTable />
          </Box>
        </Stack>
        <Box width="35%">
          <Signals />
        </Box>
      </SMainContainer>
    </>
  )
}

const SMainContainer = styled(Stack)(({ theme }) => ({
  background: theme.palette.secondary.light,
  width: "100%",
  padding: "16px 40px",
  gap: "12px",
  flexDirection: "row",
}))

const SCenterContainer = styled(Box)(({ theme }) => ({
  "display": "flex",
  "justifyContent": "center",
  "alignItems": "center",
  "width": "100%",
  "height": "100vh",

  "& .MuiAlert-filled": {
    "backgroundColor": theme.palette.error.dark,
    "color": theme.palette.secondary.main,

    "& .MuiAlert-icon": {
      display: "none",
    },
  },

  "& .MuiAlert-message": {
    fontSize: 20,
    textAlign: "center",
  },
}))

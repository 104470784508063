import React from "react"
import { Stack, Typography, styled } from "@mui/material"

import { useLocalizationContext } from "@context"
import { InfoIcons } from "./fragments"
import { useActiveQuakes } from "./_useActiveQuakes"

export const InfoBar: React.FC = () => {
  const {
    l: {
      infoBar: { currentStatus, statusMsgs, info, qty, speed, distParams },
      units,
    },
  } = useLocalizationContext()

  const { isAlert, newQuakesCount } = useActiveQuakes()

  return (
    <SInfoBarContainer>
      <InfoIcons isAlert={isAlert} />

      <Stack direction="row" alignItems="center" ml="40px" justifyContent="flex-start" width="100%" gap="50px">
        <Stack width="304px">
          <STypography>{currentStatus}</STypography>
          <STypography fontWeight={700}>{isAlert ? statusMsgs.active : statusMsgs.inactive}</STypography>
        </Stack>

        <Stack>
          <STypography>{info}</STypography>
          <Stack direction="row" alignItems="center" gap="20px">
            <STypography fontWeight={700}>{`${qty} ${newQuakesCount}`}</STypography>
            {/* <STypography fontWeight={700}>{`X: 124,32 (2,1 ${units.km})`}</STypography>
            <STypography fontWeight={700}>{`Y: 44,32 (16,1 ${units.km})`}</STypography>
            <STypography fontWeight={700}>{`Z: 2,32 (1,1 ${units.km})`}</STypography> */}
          </Stack>
        </Stack>

        {/* <Stack>
          <STypography>{distParams}</STypography>
          <STypography fontWeight={700}>{`${speed} 12,1 ${units.mps}`}</STypography>
        </Stack> */}
      </Stack>
    </SInfoBarContainer>
  )
}

const SInfoBarContainer = styled(Stack)(({ theme }) => ({
  background: theme.palette.secondary.main,
  width: "100%",
  height: "60px",
  padding: "10px 40px",
  flexDirection: "row",
  alignItems: "center",
}))

const STypography = styled(Typography)(({ theme }) => ({
  color: theme.palette.secondary.light,

  [theme.breakpoints.down("lg")]: {
    fontSize: 13,
  },
}))

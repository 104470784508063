import { useDemoScriptContext } from "@context"
import { EMessageType, IObserver, IQuake } from "@types"
import { useEffect, useMemo, useState } from "react"

export const useActiveQuakes = (): { isAlert: boolean; newQuakesCount: number } => {
  const { demoScript } = useDemoScriptContext()

  const [activeQuakes, setActiveQuakes] = useState<Record<string, boolean>>({})

  const isAlert = Object.values(activeQuakes).some((val) => val)
  const newQuakesCount = Object.values(activeQuakes).length

  const observers = useMemo(
    () => [
      {
        type: EMessageType.QUAKE,
        cb: (data: Partial<IQuake>): void => {
          setActiveQuakes((prev) =>
            data.id !== undefined && data.isActive !== undefined ? { ...prev, [data.id]: data.isActive } : prev
          )
        },
      },
      {
        type: EMessageType.STOP,
        cb: (): void => {
          setActiveQuakes({})
        },
      },
    ],
    []
  )

  useEffect(() => {
    demoScript.on(observers as IObserver<EMessageType>[])

    return () => demoScript.off(observers as IObserver<EMessageType>[])
  }, [])

  return { isAlert, newQuakesCount }
}

import React from "react"
import { Stack, styled } from "@mui/material"
import WarningRoundedIcon from "@mui/icons-material/WarningRounded"
import HelpRoundedIcon from "@mui/icons-material/HelpRounded"
import LocalHospitalRoundedIcon from "@mui/icons-material/LocalHospitalRounded"

import { theme } from "@constants"

export const InfoIcons: React.FC<{ isAlert: boolean }> = ({ isAlert }) => {
  return (
    <Stack direction="row" alignItems="center" gap="12px">
      <SIconWrapper isAlert={isAlert}>
        <WarningRoundedIcon
          htmlColor={isAlert ? undefined : theme.palette.secondary.A300}
          sx={{ width: "28px", height: "28px" }}
        />
      </SIconWrapper>
      <SIconWrapper>
        <HelpRoundedIcon htmlColor={theme.palette.secondary.A300} sx={{ width: "26px", height: "26px" }} />
      </SIconWrapper>
      <SIconWrapper>
        <LocalHospitalRoundedIcon htmlColor={theme.palette.secondary.A300} sx={{ width: "28px", height: "28px" }} />
      </SIconWrapper>
    </Stack>
  )
}

const SIconWrapper = styled(Stack, { shouldForwardProp: (prop) => prop !== "isAlert" })<{ isAlert?: boolean }>(
  ({ theme, isAlert }) => ({
    background: isAlert ? theme.palette.error.main : theme.palette.secondary.A200,
    width: "40px",
    height: "40px",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: "12px",
  })
)
